/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.7.1
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

// Bootstrap
@import "../../../../node_modules/bootstrap-scss/functions";
@import "../../../../node_modules/bootstrap-scss/variables";
@import "../../../../node_modules/bootstrap-scss/mixins";
// Variables
@import "app/variables";
// Layout
@import "app/layout";
@import "app/layout-extra";
@import "app/layout-animation";
@import "app/top-navbar";
@import "app/sidebar";
@import "app/offsidebar";
@import "app/user-block";
@import "app/settings";
// Common
@import "app/typo";
@import "app/bootstrap-reset";
@import "app/bootstrap-custom";
@import "app/button-extra";
@import "app/placeholder";
@import "app/cards";
@import "app/circles";
@import "app/dropdown-extra";
@import "app/half-float";
@import "app/animate";
@import "app/slim-scroll";
//@import "app/inputs";
@import "app/utils";
@import "app/print";
//// Elements
//@import "app/spinner";
//// Charts
//@import "app/radial-bar";
//@import "app/chart-flot";
//@import "app/chart-easypie";
//// Form elements
//@import "app/form-select2";
//@import "app/form-tags-input";
//@import "app/file-upload";
//@import "app/summernote";
//@import "app/typeahead";
//// Tables
//@import "app/table-extras";
//@import "app/table-angulargrid";
//// Maps
//@import "app/gmap";
//@import "app/vector-map";
//// Extras
//@import "app/timeline";
//@import "app/todo";
//@import "app/calendar";
//@import "app/mailbox";
//@import "app/plans";


.switch.switch-medium {
  .switch-pane {
    span {
      font-size: 12px;

      &.switch-label-checked, &.switch-label-unchecked {
        width: 12em;
      }
    }
  }
}

.chatOthers {
  font-weight: bold;
  font-style: italic;
}


@import "~@ng-select/ng-select/themes/default.theme.css";

[style*="--aspect-ratio"] > :first-child {
  width: 100%;
}
[style*="--aspect-ratio"] > img {
  height: auto;
}
@supports (--custom:property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }
  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }
  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
  [style*="--aspect-ratio"] > div > img {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: 100%;

    height: auto;
    max-width: 100%;
  }
}

.input-form {
  label:not(.switch-pane) {
    display: block;
    margin: 0.5rem 0 0 0;
  }

  .accordion > .card {
    overflow: unset;
    margin-bottom: 0;
  }
  .accordion > .card > .card-header {
    background-color: #e0e0e0;
    color: #000000;
    button {
      width: 100%;
    }
    a, a:hover, a:focus {
      color: #000000;
    }
    padding: 0.1em 0;
  }

}

.wrapper .aside-container .aside-inner {
  height: 100vh;
}

.content-wrapper {
  height: 100vh;
}
