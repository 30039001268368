/* You can add global styles to this file, and also import other style files */

//== Vendor
@import '~@fortawesome/fontawesome-free/css/brands.css';
@import '~@fortawesome/fontawesome-free/css/regular.css';
@import '~@fortawesome/fontawesome-free/css/solid.css';
@import '~@fortawesome/fontawesome-free/css/fontawesome.css';

//  @import '../node_modules/simple-line-icons/css/simple-line-icons.css';
// @import '../node_modules/weather-icons/css/weather-icons.css';
// @import '../node_modules/weather-icons/css/weather-icons-wind.css';

// @import '../node_modules/spinkit/css/spinkit.css';
// @import '../node_modules/loaders.css/loaders.css';

// @import '../node_modules/angular2-toaster/toaster.css';

//@import '../node_modules/ng2-dnd/style.css';

// @import '../node_modules/ag-grid/dist/styles/ag-grid.css';
// @import '../node_modules/ag-grid/dist/styles/theme-fresh.css';

// @import '../node_modules/ika.jvectormap/jquery-jvectormap-1.2.2.css';

// @import '../node_modules/jqcloud2/dist/jqcloud.css';

// @import '../node_modules/summernote/dist/summernote.css';

// @import '../node_modules/fullcalendar/dist/fullcalendar.min.css';

// @import '../node_modules/codemirror/lib/codemirror.css';

// @import '../node_modules/sweetalert/dist';

@import '~ngx-ui-switch/ui-switch.component.css';

//== Bootstrap
// @import "./app/shared/styles/bootstrap.scss";
@import "app/shared/styles/bootstrap4_vars.scss";
@import "node_modules/bootstrap-scss/bootstrap.scss";
//== Application

@import "./app/shared/styles/app.scss";
